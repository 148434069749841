<template>
  <div style="width:100%;height:100%;background: #F9F9F9;">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">家庭联系人</p>
    </div>
    <div class="content">
      <div
        class="box-list"
        v-for="item in peolist"
        :key="item.id"
        @click="detail(item.patient_id)"
      >
        <div class="nav">
          <div class="list">
           <span v-if="item.family_ties == null"></span>
						<span v-else>{{item.family_ties}}</span>
            <div class="list1">
              <p>
                <i>{{ item.patientname }}</i
                ><em>就诊卡号:{{ item.patient_id }}</em>
              </p>
              <img src="../assets/images/zuo.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getFamily } from '../services/home'
export default {
  data() {
    return {
      list: [],
      peolist: []
    }
  },
  async created() {
    if (!this.$route.query.user_id) {
      const mine = JSON.parse(localStorage.getItem('mine'))
      const res = await getFamily(this.$route.query.user_id || mine.id)
      if (res.data.code === 200) {
        this.list = res.data.data.rows
        console.log(this.list)
      } else {
        return res.data.msg
      }
      this.list.forEach(item => {
        if (item.mobile_num !== this.$route.query.user_name) {
          this.peolist.push(item)
        }
      })
      if (this.peolist.length === 0) {
        Toast('暂无联系人')
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    detail(id) {
      localStorage.setItem('patientId', id)
      this.$router.push({
        path: '/people?uid=' + this.$route.query.user_id,
        query: { patientId: id }
      })
    }
  }
}
</script>

<style scoped>
.back {
  z-index: 100;
}
.content {
  position: absolute;
  top: 44px;
  width: 100%;
}
.nav {
  width: 100%;
  height: 50px;
}
.nav .list {
  width: 92%;
  height: 52px;
  background: #ffffff;
  margin: 13px auto;
  border-radius: 6px;
  box-shadow: 1px 1px 1px 1px #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list1 {
  display: flex;
  justify-content: space-between;
  width: 316px;
  align-items: center;
}
.list1 p {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list1 p i {
  font-style: normal;
  width: 48px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.list1 p em {
  font-style: normal;
  width: 170px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.list1 img {
  font-size: 26px;
  font-style: normal;
  height: 17px;
  display: block;
  line-height: 18px;
  margin-right: 17px;
  color: #999999;
}
.nav div span {
  font-size: 12px;
  background: #5ca5ff;
  border-radius: 8px 0 0 8px;
  width: 19px;
  height: 53px;
  display: block;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
